@import '../../scss/variables';

.badge {
    border-radius: 1rem;
    font-size: 1rem;
    padding: 0.25rem 0.75rem;
    &.issues {
        background: $secondary-2;
        color: $primary-2;
    }
    &.new {
        background: $primary-2;
        color: $white;
    }
}
