.custom-input {
    label {
        color: $mild-darkest;
    }

    textarea.form-control {
        height: auto !important;
    }

    .form-control,
    .custom-file-label,
    .form-select {
        font: normal $font-weight-400 1rem/1.625rem $secondary-font;
        letter-spacing: 0.002em;
        padding: 0.75rem 0.5rem;
        border-radius: 4px;
        height: 3.75rem;
        border: 1px solid rgba(36, 94, 125, 0.3);
        color: $mild-dark;
        box-shadow: none !important;

        &:focus,
        &:active,
        &.active,
        &:hover {
            color: $mild-darkest;
            background-color: $white;
            border-color: $bg-500;
            outline: 0;
            box-shadow: none;
        }

        &:disabled,
        &.disabled {
            border: 1px solid $grey-2;
            background-color: $netural;
        }

        option {
            font: normal $font-weight-400 1rem/1.625rem $secondary-font;
            letter-spacing: 0.002em;
            box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.05), 4px 0px 4px 0px rgba(0, 0, 0, 0.05),
                -4px 0px 4px 0px rgba(0, 0, 0, 0.05);
        }
    }

    .input-group-append {
        margin-left: 0px !important;

        .input-group-text {
            background: none;
            border-top-right-radius: 2px;
            border-bottom-right-radius: 2px;
            border: 1px solid $primary-2;
            border-left: none;
            margin-left: 0px;
            padding: 0.3rem 0.75rem;
        }
    }

    &.search-input-container {
        .btn-circle {
            border-radius: 24px !important;
            margin-left: -3rem !important;
            z-index: 9;
            height: 3rem;
            width: 3rem;

            i {
                font-size: 20px;
            }
        }

        .custom-search-input {
            width: 24.438rem;
            height: 3rem !important;
            border: none !important;
            border-radius: 24px !important;
            padding: 0.75rem 1rem;
            &.disabled,
            &:disabled {
                background-color: $white !important;
                opacity: $disabled-opacity;
            }
        }

        .custom-drop-down {
            width: 9.25rem;
        }

        .custom-label {
            margin-bottom: 0;
            margin-top: 0.4rem;
            margin-right: 0.5rem;
        }
    }

    &.checkbox-input-container {
        padding-left: 1.25rem;
        gap: 0.604rem;
        align-items: center;

        .custom-checkbox {
            position: relative;
            margin-top: 0;
            margin-bottom: 0;
            width: 1.25rem;
            height: 1.25rem;
            border: 2px solid $light-grey;
            background-color: transparent;
            transition: all 0.15s;
        }

        .custom-checkbox:focus {
            outline: none;
            box-shadow: none;
        }

        .custom-checkbox:checked {
            border-color: $turquoise;
            background-color: $turquoise;
        }

        .custom-checkbox:disabled {
            border-color: $light-grey;
            background-color: $light-grey-bg;
        }

        .custom-label {
            letter-spacing: 0.002em;
            padding: 0;
            margin: 0;
        }
    }

    .form-check {
        input {
            position: relative;
            margin-top: 0;
            margin-bottom: 0;
            width: 1.25rem;
            height: 1.25rem;
            border: 1px solid $light-grey;
            background-color: transparent;
            transition: all 0.15s;
            &:checked {
                border-color: $turquoise;
                background-color: $turquoise;
            }
        }
        label {
            letter-spacing: 0.002em;
            margin: 0;
            font: normal $font-weight-600 0.875rem/1.375rem $secondary-font;
            padding: 0 0 0 0.5rem !important;
        }
    }
}

.custom-label {
    &.white-important {
        font: normal $font-weight-400 0.875rem/1.5rem $secondary-font;
    }
}

.custom-dropdown-dark {
    > .dropdown {
        .dropdown-toggle {
            height: 3.75rem;
            width: 100%;
            &.btn-primary {
                background: $bg-400;
                border: 1px solid rgba(239, 240, 243, 0.2);
                border-radius: 4px;
                text-align: left;
                white-space: normal !important;
                word-wrap: break-word;
                &:hover,
                &:focus,
                &:active,
                &:visited {
                    border-color: 1px solid rgba(239, 240, 243, 0.2);
                    box-shadow: none !important;
                    color: $white !important;
                }
            }
            &::after {
                float: right;
                margin-top: 0.5rem;
            }
        }
        .dropdown-menu {
            width: 92.4%;
            border-radius: 0;
            padding: 0;
            margin-top: 0;
            background: $bg-400;
            border: 1px solid rgba(239, 240, 243, 0.2);

            .dropdown-item {
                color: $white;
                padding: 0.5rem 0.75rem !important;
                &:hover,
                &:focus {
                    background-color: $dark-grey !important;
                    color: $white !important;
                }
            }
        }
        ul.dropdown-menu-custom {
            background: $bg-400;
            color: $white;
            list-style-type: none;
            padding-left: 0rem;
            position: relative;
            z-index: 9;
            border: 1px solid rgba(239, 240, 243, 0.2);
            box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.05), 4px 0px 4px 0px rgba(0, 0, 0, 0.05),
                -4px 0px 4px 0px rgba(0, 0, 0, 0.05);
            li {
                padding: 0.5rem 0.75rem !important;
                cursor: pointer;
                &:hover {
                    background: $dark-grey;
                }
                &.showMoreLess {
                    background: $bg-300;
                    padding: 0.4rem 0.75rem !important;
                }
            }
        }
    }
}
