// Variable overrides
// Typography
$primary-font: 'Fira Sans', sans-serif;
$secondary-font: 'Source Sans Pro', sans-serif;

$font-weight-900: 900;
$font-weight-600: 600;
$font-weight-700: 700;
$font-weight-400: 400;
$font-weight-300: 300;

$font-40: 40px;
$font-36: 36px;
$font-28: 28px;
$font-24: 24px;
$font-20: 20px;
$font-18: 18px;
$font-14: 14px;

// Colors
$primary-1: #ffffff;
$primary-2: #000000;
$white: #ffffff;

$secondary-1: #f3c716;
$secondary-2: #ffe293;
$secondary-3: #ffefc6;
$netural: #f0f0f0;

$black-1: #09112f;

$black: #0f172a;
$turquoise: #4ad0ee;
$turquoise-05: rgba(68, 171, 194, 0.5);
$card-border: #bdcfd8;
$summary-left-background: #3fb1ca;
$golden: #fec400;
$coral-red: #f44336;
$dark-grey: #5f6985;
$dark-grey-02: rgba(239, 240, 243, 0.2);

$apple-green: #4caf50;
$bg-100: linear-gradient(167.96deg, #05103a 0%, #0c1433 49.79%, #000000 100%);
$bg-200: #0d1a46;
$bg-300: #14224f;
$bg-400: #293560;
$bg-500: #245e7d;
$light-grey: #878ea0;
$scrollbar-track-dark: #20294c;
$scrollbar-track-light: #f3f4f5;
$scrollbar-thumb: #878ea033;
$primary-btn-hover: #3fb2cc;
$secondary-btn-hover: #6c7180;
$light-grey-bg: #f5f6f7;
$dark-blue: #071138;

$gray: grey;

$grey-darkest: #475569;
$grey-dark: #94a3b8;
$grey: #cbd5e1;
$grey-light: #e2e8f0;
$grey-light-1: #f6f5f5;
$grey-lightest: #f1f5f9;
$grey-1: #cbcbcb;

$grey-2: #9f9f9f;
$mild-dark: #4f4f4f;
$mild-darkest: #333333;
$table-bg-accent: $grey-light-1;
$indigo: #293560;

$light-grey-1: #ddd;
$light-grey-2: #e5e5e5;
$grey-2: #eff0f3;

$stroke: linear-gradient(133.98deg, #070e29 16.55%, #0b1334 86.38%);
$border: #070e29;
/* $table-striped-order: $grey-light-1; */

$success: $apple-green;
$success-bg: #f1f9f1;

$danger: #f43f5e;
$danger-bg: #fef0ef;

$warning: #ffc107;
$warning-bg: #fffaeb;

$turquoise-bg: #f1fbfe;

$hyperlink: #1e40af;
$info: #1e40af;
$danger-1: #be123c;

// Buttons

$btn-border-primary: $turquoise;
$btn-background-primary: $turquoise;
$btn-hover-background-primary: $turquoise;
$btn-text-color-primary: $white;

$btn-border-error: $danger-1;
$btn-background-error: $white;
$btn-hover-background-error: $danger;
$btn-text-color-error: $danger-1;

$btn-lg-text-font-size: 24px;
$btn-md-text-font-size: 18px;
$btn-sm-text-font-size: 14px;

$btn-font-family: 'Source Sans Pro', sans-serif;
$btn-font-weight: $font-weight-600;

// logo

$shared-studios-logo: '../assets/logo/logo.svg';
$header-height: 0px;
$header-light-bg: $primary-2;
//body
$body-bg: $white;
$body-color: $primary-2;
$grid-gutter-width: 0px;
$font-family-base: $primary-font;

$breadcrumb-divider: quote('\e905') !default;

$hover-border: 0.0625rem solid #f2f2f2;
$hover-box-sizing: border-box;
$hover-box-shadow: -0.375rem 0.375rem 0.625rem rgba(109, 109, 109, 0.1);

$avatar-width: 3.25rem;
$avatar-status-width: 3.25rem;
$sidebar-nav-link-padding-y: 1.1rem;
$sidebar-nav-link-padding-x: 1rem;

$border-radius: 0.5rem;
$sidebar-width: 100px;
$sidebar-minimized-width: 100px;
$disabled-opacity: 0.5;
