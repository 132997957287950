// card
.card-custom {
    border: 0;
    &.card-sm {
        height: 5.875rem;
        width: 14.455rem;
        background: $white;
        box-shadow: 0px 16px 24px rgba(0, 0, 0, 0.06), 0px 2px 6px rgba(0, 0, 0, 0.04), 0px 0px 1px rgba(0, 0, 0, 0.04);
        .custom-padding {
            padding: 1.313rem 1.125rem;
        }
    }
    &.card-lg {
        margin-bottom: 0;
        width: 31rem;
        height: 12.875rem;
        background: $white;
        box-shadow: 0px 6px 10px rgba(109, 109, 109, 0.1);
        .card-body {
            padding: 2.5rem 2.313rem !important;
        }
    }
    &.card-with-img {
        width: 18.063rem;
        height: 18.063rem;
        background: $white;
        box-shadow: 0px 0px 1px rgba(15, 23, 42, 0.06), 0px 10px 15px -3px rgba(15, 23, 42, 0.1),
            0px 4px 6px -2px rgba(15, 23, 42, 0.05);
        border-radius: $border-radius;

        .img-status {
            cursor: pointer;
            img {
                width: 18.125rem;
                height: 14.063rem;
            }
            .portal-state {
                position: absolute;
                top: 12.2rem;
                left: 0.4rem;
                .green {
                    color: $success;
                }
                .red {
                    color: $danger;
                }
                .orange {
                    color: $warning;
                }
                &.orange-adjustment {
                    top: 12.05rem;
                    left: 0.4rem;
                    font-size: 1.475rem;
                }
            }
            .portal-status {
                position: absolute;
                top: 12.4rem;
                right: 0.5rem;
                &.green {
                    color: $success;
                }
                &.red {
                    color: $danger;
                }
            }
            .blackdrop {
                cursor: default !important;
                position: absolute;
                background: rgba(0, 0, 0, 0.73);
                color: $white;
                top: 0;
                width: 18.125rem;
                height: 14.063rem;
                border-radius: $border-radius;
                .text {
                    position: relative;
                    padding: 4rem 1rem;
                    text-align: center;
                }
                .blackdrop-btn {
                    position: relative;
                    margin-top: -2.6rem;
                    margin-left: 6.7rem;
                    background: transparent !important;
                    color: $white;
                    border: 1px solid $white !important;
                    width: 4.813rem;
                    height: 2.25rem;
                    font: normal $font-weight-700 0.875rem/1.5rem $primary-font !important;
                    padding: 0;
                    border-radius: 2px;
                    cursor: pointer !important;
                }
            }
        }
        .card-body {
            padding: 1.25rem;
            cursor: pointer;
        }
        & img {
            border-radius: $border-radius $border-radius 0 0;
        }
    }
}

@media screen and (max-width: 768px) {
    .card-custom {
        &.card-lg {
            /* zoom: 0.7; */
            transform: scale(0.7);
            transform-origin: center center;
            margin: 0rem -3rem -5rem -4.5rem;
        }
    }
}
