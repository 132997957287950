.custom-toast {
    .toast {
        &.error {
            border: 1px solid $danger;
        }
        &.warning {
            border: 1px solid $warning;
        }
        &.success {
            border: 1px solid $success;
        }
        &.info {
            border: 1px solid $info;
        }
    }
    .toast-header {
        &.error {
            color: $danger;
        }
        &.warning {
            color: $warning;
        }
        &.success {
            color: $success;
        }
        &.info {
            color: $info;
        }
    }
    .toast-body {
        .error {
            color: $danger;
        }
        .warning {
            color: $warning;
        }
        .success {
            color: $success;
        }
        .info {
            color: $info;
        }
    }
}
