@import '../../../scss/variables';

.login {
    --login-page-padding: 50px;

    max-width: 100%;
    min-height: 100vh;
    padding: var(--login-page-padding);
    background: $bg-100;
    box-sizing: border-box !important;
}

.login-form {
    max-width: 500px;
    padding: 0;
    margin: 50px 0;
}

.login-input {
    background-color: $indigo;
    color: $white !important;
    &:hover {
        background-color: $indigo !important;
        border: 1px solid $white !important;
    }
    &:focus {
        border: 1px solid $white !important;
        background-color: $indigo !important;
    }
}

.submit-btn {
    padding: 15px 84px;
    border-radius: 24px;
    background-color: transparent;
    border: 2px solid $turquoise;
    color: $turquoise;
    font-size: 16px;
    font-weight: 600;
    &:hover,
    &:active,
    &:focus {
        background-color: transparent !important;
        border: 2px solid $white !important;
    }
}

.complete-fp {
    max-width: 500px;
    padding: 0;
    margin: 0px 0;
}

.keep-sign-lbl,
.blackspot-label {
    font: normal $font-weight-600 0.875rem/1.375rem $secondary-font;
    color: $white !important;
    &.align-super {
        vertical-align: super !important;
    }
}

.forgot {
    font-weight: $font-weight-600;
    text-decoration: underline;
}

.bottom-margin-40 {
    margin-bottom: 40px;
}

.bottom-margin-30 {
    margin-bottom: 30px;
}
.bottom-margin-15 {
    margin-bottom: 15px;
}

.password-policy-container {
    color: $white;
    > .section {
        display: inline-flex;
        width: 50%;
        > .dot {
            height: 7px;
            width: 7px;
            background: $turquoise;
            border-radius: 10px;
            display: inline-block;
            position: absolute;
            margin-top: 0.3rem;
        }
        .policy-text {
            margin-left: 1rem;
        }
    }
}

@media (max-width: 768px) {
    * {
        box-sizing: border-box !important;
    }

    .login {
        --login-page-padding: calc(15px + (50 - 15) * ((100vw - 320px) / (768 - 320)));
    }
}

.login-form {
    > .custom-input {
        position: relative;
    }
}

.sso-login-form {
    max-width: 500px;
    padding: 0;
    margin-top: -10.5rem;
}

.text-with-margin {
    margin-top: 5rem;
}

.header-label {
    font-size: 16px;
    font-weight: 600;
    color: $white;
    margin-bottom: 20px;
}

// Horizontal Line with text
.separator {
    display: flex;
    align-items: center;
    text-align: center;
    color: $light-grey;
}

.separator::before,
.separator::after {
    content: '';
    flex: 1;
    border-bottom: 1px solid $light-grey;
}

.separator:not(:empty)::before {
    margin-right: 0.25em;
}

.separator:not(:empty)::after {
    margin-left: 0.25em;
}

// Vertical Line with text
.wrapper {
    position: relative;
    width: 190px;
    height: 380px;
}

.vertical-divider {
    position: absolute;
    display: table;
    text-align: center;
    height: 100%;
    width: 88%;

    .center-element {
        position: relative;
        display: table-cell;
        vertical-align: middle;
        color: $light-grey;

        &:before,
        &:after {
            position: absolute;
            content: '';
            width: 1px;
            left: 50%;
            border-left: 1px solid $light-grey;
        }
        &:before {
            bottom: 50%;
            top: 0;
            margin-bottom: 20px;
        }
        &:after {
            top: 50%;
            bottom: 0;
            margin-top: 20px;
        }
    }
}
