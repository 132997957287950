@import '../../../scss/variables';

.forgot-password {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    margin: 0;
    padding: 50px !important;
    max-width: 100% !important;
    min-height: 100%;
    background: $bg-100;
}

.forgot-form {
    max-width: 500px !important;
    padding: 0 !important;
    margin: 50px 0 !important;
}
