@import 'custom/cards';
@import 'custom/button';
@import 'custom/chart';
@import 'custom/breadcrumbs';
@import 'custom/custom_sidebar';
@import 'custom/theme';
@import 'custom/font_size';
@import 'custom/modal';
@import 'custom/header';
@import 'custom/toast';
@import 'custom/input';
@import 'custom/slider';
@import 'custom/popover';
@import './variables';

// Here you can add other styles
body {
    overflow-y: auto;
    overflow-x: hidden;
}
.c-body {
    .c-main {
        background-color: $grey-2;
        padding: 1.3rem 3rem 0rem 3rem !important;
    }
}

.custom-hyper-ref {
    text-decoration: none;
    color: $black-1;
}

.custom-hyper-ref:hover {
    text-decoration: none;
    color: $grey-dark;
    cursor: pointer;
}

.custom-cusor-pointer {
    cursor: pointer;
}
// font

h1,
.heading1-primary {
    /*32px/38.4px*/
    font: normal $font-weight-700 2rem/2.4rem $primary-font;
    letter-spacing: 0.005em;
}

h2,
.heading2-primary {
    /*28px/33.6px*/
    font: normal $font-weight-700 1.75rem/2.1rem $primary-font;
    letter-spacing: 0.005em;
}
h3,
.heading3-primary {
    /*24px/28.8px*/
    font: normal $font-weight-700 1.5rem/1.8rem $primary-font;
    letter-spacing: 0.005em;
}

h4,
.heading4-primary {
    /*20px/24px*/
    font: normal $font-weight-700 1.25rem/1.5rem $primary-font;
    letter-spacing: 0.005em;
}
h5,
.heading5-primary {
    /*20px/24px*/
    font: normal $font-weight-600 1.25rem/1.5rem $primary-font;
    letter-spacing: 0.005em;
}
h6,
.heading6-primary {
    /*18px/21.6px*/
    font: normal $font-weight-600 1.125rem/1.35rem $primary-font;
    letter-spacing: 0.005em;
}

.body1-primary {
    /*16px/24px*/
    font: normal $font-weight-400 1rem/1.5rem $primary-font;
}
.body2-primary {
    /*16px/26px*/
    font: normal $font-weight-600 1rem/1.6875rem $primary-font;
    letter-spacing: 0.002em;
}
.body3-primary {
    /*12px/20px*/
    font: normal $font-weight-600 0.75rem/1.25rem $primary-font;
    letter-spacing: 0.002em;
}
.body5-primary {
    /*12px/20px*/
    font: normal $font-weight-400 0.75rem/1.25rem $primary-font;
    letter-spacing: 0.002em;
}

.heading1-secondary {
    /*28px/34px*/
    font: normal $font-weight-600 1.75rem/2.125rem $secondary-font;
}
.heading2-secondary {
    /*48px/60px*/
    font: normal $font-weight-600 3rem/3.875rem $secondary-font;
}
.heading3-secondary {
    /*32px/38.4px*/
    font: normal $font-weight-700 2rem/2.4rem $secondary-font;
}
.heading4-secondary {
    /*20px/24px*/
    font: normal $font-weight-700 1.25rem/1.5rem $secondary-font;
}
.custom1-primary {
    /*12px/24px*/
    font: normal $font-weight-600 0.75rem/1.5rem $primary-font;
    letter-spacing: 0.02em;
}

.body1-secondary {
    /* 18px/28px */
    font: normal $font-weight-600 1.125rem/1.75rem $secondary-font;
    letter-spacing: 0.002em;
}
.body2-secondary {
    /* 16px/26px */
    font: normal $font-weight-600 1rem/1.625rem $secondary-font;
    letter-spacing: 0.002em;
}
.body3-secondary {
    /* 16px/26px */
    font: normal $font-weight-400 1rem/1.625rem $secondary-font;
    letter-spacing: 0.002em;
}
.body4-secondary {
    /* 14px/22px */
    font: normal $font-weight-400 0.875rem/1.375rem $secondary-font;
    letter-spacing: 0.002em;
}
.body5-secondary {
    /* 12px/20px */
    font: normal $font-weight-400 0.75rem/1.25rem $secondary-font;
    letter-spacing: 0.002em;
}
a {
    font: normal $font-weight-400 0.875rem/1.375rem $secondary-font;
    letter-spacing: 0.01em;
}

.color-primary-1 {
    color: $primary-1;
}
.color-primary-2 {
    color: $primary-2;
}
.color-secondary-1 {
    color: $secondary-1;
}
.color-secondary-2 {
    color: $secondary-2;
}
.color-secondary-3 {
    color: $secondary-3;
}
.color-neutral {
    color: $netural;
}

.bg-turquoise {
    background: $turquoise;
}
.bg-golden {
    background: $golden;
}
.bg-coral-red {
    background: $coral-red;
}
.bg-dark-grey {
    background: $dark-grey;
}
.bg-apple-green {
    background: $apple-green;
}
.bg-100 {
    background: $bg-100;
}
.bg-200 {
    background: $bg-200;
}
.bg-300 {
    background: $bg-300;
}
.bg-400 {
    background: $bg-400;
}

.bg-secondary-1 {
    background: $secondary-1;
}
.bg-secondary-2 {
    background: $secondary-2;
}
.bg-secondary-3 {
    background: $secondary-3;
}
.bg-neutral {
    background: $netural;
}

.border-1 {
    border: 0.0625rem solid $primary-2;
}
.w-fit-content {
    width: fit-content !important;
}
.wper-5 {
    width: 5%;
}
.wper-7 {
    width: 7%;
}
.wper-18 {
    width: 18%;
}
.wper-9 {
    width: 9%;
}
.wper-10 {
    width: 10%;
}
.wper-14 {
    width: 14%;
}
.wper-15 {
    width: 15%;
}
.wper-20 {
    width: 20%;
}
.wper-75 {
    width: 75%;
}
.wper-80 {
    width: 80%;
}
.wper-85 {
    width: 85%;
}

.wper-90 {
    width: 90%;
}

.text-underline {
    text-decoration: underline;
}

.lh-0 {
    line-height: 0 !important;
}
.vertical-align-middle {
    vertical-align: middle !important;
}
.cursor-pointer {
    cursor: pointer !important;
}
// the box with smileys
.over-all-rating {
    padding: 1.175rem 1.69rem;
    background-color: $secondary-1 !important;
    display: flex;
    .heading-text {
        width: 8.691%;
    }
    .smiley {
        width: 17.506%;
    }
}

// user score table
.overall-event-quality {
    .header {
        padding: 0.815rem 0.69rem;
        margin-bottom: 1.8rem;
        background-color: $secondary-3;
    }
    .section {
        padding: 0.815rem 0.025rem;
        height: calc(75vh - 50px);
        overflow: auto;
        &.auto-height {
            height: auto !important;
        }
        .custom-progress {
            padding-right: 3.7rem;
        }
        .user-name-mobile {
            word-wrap: break-word;
        }
        /* width */
        &::-webkit-scrollbar {
            width: 3.11px;
        }
        /* Handle */
        &::-webkit-scrollbar-thumb {
            background: rgba(0, 0, 0, 0.5);
            border-radius: 2px;
            transform: matrix(-1, 0, 0, 1, 0, 0);
        }
        .content {
            .primary {
                height: 0.625rem;
                .progress-bar {
                    background-color: $secondary-1 !important;
                    border-radius: 0px 0.38rem 0.38rem 0px;
                    font-weight: $font-weight-600;
                }
            }
            .secondary {
                height: 0.625rem;
                .progress-bar {
                    background-color: $primary-2 !important;
                    border-radius: 0px 0.38rem 0.38rem 0px;
                    font-weight: $font-weight-600;
                }
            }
            &:hover {
                border: $hover-border;
                box-sizing: $hover-box-sizing;
                box-shadow: $hover-box-shadow;
                cursor: pointer;
            }
            &.disabledClick:hover {
                cursor: default;
                border: none !important;
                box-sizing: $hover-box-sizing;
                box-shadow: none !important;
            }
        }
    }
}

.chart-container {
    canvas {
        height: 15rem;
    }
    box-shadow: 0px 2px 2px rgba(105, 105, 105, 0.25);
    height: 16.65rem;
    .header {
        background-color: $secondary-3;
        padding: 0.38rem 1.69rem;
        width: 19rem;
        height: 2.5rem;
        font-size: 20px;
        font-weight: 600;
        line-height: 25px;
    }
}

// color temporary blocks
.color-block {
    height: 6.25rem;
    width: 6.25rem;
}
.color-block-big {
    height: 6.25rem;
    width: 12.5rem;
}

@media screen and (max-width: 768px) {
    *:not(.overall-event-quality) {
        box-sizing: content-box;
    }
}

.c-app {
    min-height: 100%;
    .c-wrapper {
        background: $light-grey-2;
        .toggler {
            height: 0rem;
            margin-left: -1rem;
            z-index: 9999;
            /* position: relative;
      top: 1.3rem; */
            top: 6.6rem;
            position: fixed;
            .cont {
                cursor: pointer;
                height: 2rem;
                width: 2rem;
                background: $turquoise;
                border-radius: 1rem;
                padding: 0.3rem 0.4rem 0.1rem 0.4rem;
                svg {
                    color: $white;
                }
            }
        }
    }
}

.custom-img {
    width: 100%;
    height: auto;
}
.custom-img-small {
    border: 1px solid $grey-1;
    width: 5rem;
    height: 5rem;
}
.custom-table {
    &.table {
        border: none !important;
    }
    &.table th {
        border: none !important;
        font: normal $font-weight-700 1rem/1.5rem $primary-font;
    }
    &.table td {
        vertical-align: middle;
        border: none !important;
        font: normal $font-weight-400 1rem/1.5rem $primary-font;
    }
    .edit {
        &:hover {
            background: rgba(0, 0, 0, 0.05) !important;
            cursor: pointer;
        }
    }
}

.custom-link {
    text-decoration: underline;
    color: $hyperlink;
}
.disabled {
    cursor: default;
    pointer-events: none;
    opacity: $disabled-opacity;
}
.white {
    color: $white;
}
.coral-red {
    color: $coral-red;
}
.white-important {
    color: $white !important;
}
.black {
    color: $black !important;
}
.turquoise {
    color: $turquoise;
}
.dark-grey {
    color: $dark-grey;
}

/* scrollbar class */
.custom-scrollbar {
    &::-webkit-scrollbar-thumb {
        background-color: $scrollbar-thumb;
        border-radius: 1.875rem;
        &:hover {
            background-color: $light-grey;
            border-radius: 1.875rem;
        }
    }
    &::-webkit-scrollbar {
        width: 0.75rem;
    }
    &.dark {
        &::-webkit-scrollbar-track {
            background-color: $scrollbar-track-dark;
        }
    }
    &.light {
        &::-webkit-scrollbar-track {
            background-color: $scrollbar-track-light;
        }
    }
}
::selection {
    background: $turquoise;
}
.dropdown-item {
    &:active,
    &:hover {
        background: $grey-2 !important;
        color: $black-1 !important;
    }
}

.custom-sidebar-tooltip {
    .bs-tooltip-auto {
        &.show {
            z-index: 999999 !important;
            opacity: 1 !important;
            padding: 0 !important;
            margin-left: -1rem;
            .custom-tooltip {
                background: $turquoise;
                font: normal 600 0.75rem/1.25rem $secondary-font;
                letter-spacing: 0.002em;
                padding: 5px 8px !important;
            }
        }
    }
    &.fade {
        transition: none !important;
    }
}
@media (max-width: 991.98px) {
    .c-sidebar-backdrop {
        display: none;
        .c-show {
            opacity: 0.5;
        }
    }
    .c-wrapper {
        margin-left: 3.5rem !important;
    }
}
.danger-important {
    color: $danger !important;
    margin-bottom: 0 !important;
}

.password {
    position: absolute;
    right: 0.5rem;
    top: 55%;
    cursor: pointer;
    z-index: 4;
    &.show-password {
        color: $white;
    }
    &.hide-password {
        color: $white;
    }
    &.sign-up {
        top: 31% !important;
    }
}
.session-expired-container {
    position: fixed;
    height: 100vh;
    width: 100vw;
    z-index: 9999;
    backdrop-filter: blur(10px);
    background: transparent;
    top: 0;
    left: 0;
    .session-expired {
        position: absolute;
        inset: 0;
        margin: auto;
        border: 1px solid $light-grey;
        height: 7rem;
        width: 20rem;
        background: $white;
        border-radius: $border-radius;
    }
}
.portal-card-container {
    overflow: hidden;
    width: 100%;
    margin: 1.5rem -3.125rem 2rem 0.012rem !important;

    .rdt_TableHeadRow {
        background: $bg-400;
        color: $white;
        font: normal $font-weight-600 1rem/1.625rem $secondary-font;
        letter-spacing: 0.002em;
        height: 3.75rem;
        border-top-left-radius: 4px;
        border-top-right-radius: 4px;
        padding-left: 1.875rem;
        justify-content: center;

        > div {
            padding-left: 0 !important;
        }

        .rdt_TableCol_Sortable {
            &:hover {
                opacity: 1 !important;
            }
        }
    }

    .rdt_TableBody {
        .rdt_TableRow {
            color: $black !important;
            font: normal $font-weight-400 1rem/1.625rem $secondary-font;
            letter-spacing: 0.002em;
            height: 4rem;
            padding-left: 1.875rem;

            > div {
                padding-left: 0 !important;
            }

            &:hover {
                background: rgba(74, 208, 238, 0.08);
            }

            &:not(:last-of-type) {
                border-bottom-style: solid;
                border-bottom-width: 1px;
                border-bottom-color: $grey-2;
            }

            .rdt_TableCell:first-child {
                font-weight: $font-weight-600;
                > div {
                    white-space: break-spaces;
                }
            }

            .rdt_TableCell:nth-child(2),
            .rdt_TableCell:nth-child(3),
            .rdt_TableCell:nth-child(4) {
                color: $black-1;
            }
            .rdt_TableCell:nth-child(4) div {
                white-space: break-spaces;
                overflow: visible;
            }

            .actions {
                display: inline-flex;
                gap: 1.025rem;

                span {
                    font-size: 1.5rem;
                }

                .btns {
                    cursor: pointer;

                    &:hover {
                        span::before {
                            color: $secondary-btn-hover !important;
                        }
                    }

                    &:disabled,
                    &.disabled {
                        cursor: default;
                        pointer-events: none;
                        opacity: $disabled-opacity;
                    }
                }
            }
        }

        .rdt_ExpanderRow {
            padding: 1.25rem 1.875rem;
        }
    }

    .rdt_Pagination {
        font: normal $font-weight-400 0.75rem/1.25rem $secondary-font;
        letter-spacing: 0.002em;
        padding: 2.063rem 2.5rem;

        span {
            font-size: 0.875rem;
            color: $dark-grey;
        }

        > span:first-of-type {
            margin-left: 1.5rem;
            order: 2;
            padding-left: 1rem;
        }

        span:nth-of-type(2) {
            order: 1;
            margin: 0;
        }

        > span:nth-of-type(2)::before {
            content: 'Showing ';
        }

        > div:first-of-type {
            order: 3;

            select {
                width: 34px;
                height: 38px;
                font-size: 1rem;
                line-height: 1.625rem;
                border: 1px solid $border;
                padding: 0 1rem;
                margin-left: 1rem;
                border-radius: $border-radius;
            }

            svg {
                margin-top: 6px;
                margin-right: 10px;

                @media (max-width: 768px) {
                    margin: 6px 5px;
                }
            }
        }

        > div:nth-of-type(2) {
            order: 4;
            margin-left: auto;
        }

        button {
            padding: 0;
            margin: 0 0.5rem;
            border: 1px solid $border;
            border-radius: $border-radius;
            fill: $black-1;
        }

        button:hover:enabled {
            background-color: $border;
            fill: $primary-1;
        }

        button:disabled {
            border-color: $light-grey;
            fill: $light-grey;
        }
    }
    &.activity {
        .rdt_TableBody {
            border-left: 1px solid $grey-2;
            border-right: 1px solid $grey-2;
        }
    }
}
.page-body {
    &.table-body {
        margin-top: 2em;
        background: $white;
        border-radius: 0.25rem;
        margin-bottom: 2rem;
        .nav-tabs {
            .nav-item {
                padding: 0.5rem 0.5rem 0rem 0.5rem; /* border: 1px solid red; */
                .nav-link {
                    color: $primary-2 !important;
                    font: normal $font-weight-400 1rem/1.5rem $primary-font;
                    &.active {
                        font: normal $font-weight-700 1rem/1.5rem $primary-font;
                        color: $primary-2 !important;
                        border-color: transparent;
                        border-bottom: 4px solid $black-1;
                        border-top-left-radius: 3rem;
                        border-top-right-radius: 3rem;
                    }
                    &:hover {
                        border-color: transparent;
                        border-bottom: 4px solid $black-1;
                        border-top-left-radius: 3rem;
                        border-top-right-radius: 3rem;
                    }
                }
            }
        }

        .tab-content {
            padding: 1.5rem;
            /* border: 1px solid red; */
            .portal-configurations {
                .config-container {
                    .upper-container {
                        border-top-right-radius: 0.25rem;
                        border-top-left-radius: 0.25rem;
                    }
                    .down-container {
                        border-bottom-right-radius: 0.25rem;
                        border-bottom-left-radius: 0.25rem;
                    }
                }
            }
        }
    }
}
.mb-5rem {
    margin-bottom: 5rem !important;
}

.bar-chart-wrapper {
    margin-top: 30px;
}

.line-chart-wrapper {
    margin-top: 30px;
}
.custom-nav-link::after {
    content: attr(title);
    position: absolute;
    bottom: -100%; /* Position the text below the link */
    left: 0;
    width: 100%;
    background-color: $bg-200; /* Semi-transparent background */
    color: $white; /* Text color */
    padding: 5px 10px; /* Padding for the text */
    border-radius: 3px; /* Rounded corners */
    opacity: 0; /* Initially hidden */
    transition: opacity 0.3s ease-in-out; /* Smooth transition on hover */
    z-index: 10; /* Ensure text is above other elements */
}
.custom-nav-link:hover::after {
    opacity: 1; /* Show the text on hover */
}
/* .custom-nav-link[disabled]:hover::after */
