// sidebar
.custom-modal {
    .modal-content {
        border: none !important;
        /* width: 37.188rem;  //595px */
        border-radius: $border-radius !important;
        height: auto;
        .modal-header {
            background-color: transparent !important;
            color: $primary-2;
            border: none;
            padding: 1.5rem 1.5rem 0 1.5rem;
            .modal-close-header {
                border: 1px solid $primary-2;
                height: 1.5rem;
                width: 1.5rem;
                text-align: center;
                cursor: pointer;
                &:hover {
                    background: $grey-lightest !important;
                }
            }
        }
        .modal-body {
            padding: 1.5rem 1.5rem 1rem 1.5rem;
            .modal-circle {
                &.with-icon {
                    padding: 1.313rem !important;
                    line-height: 1;
                    border-radius: 3rem;
                    background: $turquoise;
                    &.danger-warning {
                        background: $danger !important;
                    }
                    .icon-save,
                    .icon-warning {
                        font-size: 2.5rem;
                        vertical-align: middle;
                        &::before {
                            color: $white;
                        }
                    }
                }
            }
        }
        .modal-footer {
            border: none;
            justify-content: flex-end;
            grid-column-gap: 0.35rem;
        }
        .modal-circle {
            svg {
                background-color: $golden;
                border-radius: 50%;
            }
        }
    }
    @media (min-width: 576px) {
        .modal-dialog-centered {
            max-width: 681px;
        }
    }
}
