.custom-header {
    background-color: $white !important;
    height: 5rem;
    .dropdown-menu {
        padding: 0;
        .dropdown-item {
            &:hover {
                text-decoration: none;
                color: #455164;
                background: rgba(0, 0, 0, 0.05);
                border-radius: $border-radius;
            }
        }
    }
    .c-avatar {
        width: 3rem;
        height: 3rem;
        margin-right: 0.75rem;

        .c-avatar-img {
            height: 3rem;
            width: 3rem;
            border-radius: 50%;
            border: 1px solid;
            background: $turquoise;

            .icon-users::before {
                color: $white !important;
            }
        }
    }
    .chevron-down {
        margin-left: 1.5rem;
        svg {
            color: $black-1;
        }
    }
    .avatar-name {
        color: $black-1;
        letter-spacing: 0.005em;
    }
}
