@import '../../scss/variables';

.msg-wrapper {
    display: flex;
    flex: 1;
    flex-flow: row-reverse;
    span {
        width: fit-content;
        position: relative;
        padding: 1rem;
        color: $white;
        background-color: $light-grey;
    }
}
