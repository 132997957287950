.font-1px {
    font-size: 0.063rem;
}
.font-2px {
    font-size: 0.125rem;
}
.font-3px {
    font-size: 0.188rem;
}
.font-4px {
    font-size: 0.25rem;
}
.font-5px {
    font-size: 0.313rem;
}
.font-6px {
    font-size: 0.375rem;
}
.font-7px {
    font-size: 0.438rem;
}
.font-8px {
    font-size: 0.5rem;
}
.font-9px {
    font-size: 0.563rem;
}
.font-10px {
    font-size: 0.625rem;
}
.font-11px {
    font-size: 0.688rem;
}
.font-12px {
    font-size: 0.75rem;
}
.font-13px {
    font-size: 0.813rem;
}
.font-14px {
    font-size: 0.875rem;
}
.font-15px {
    font-size: 0.938rem;
}
.font-16px {
    font-size: 1rem;
}
.font-17px {
    font-size: 1.063rem;
}
.font-18px {
    font-size: 1.125;
}
.font-19px {
    font-size: 1.188rem;
}
.font-20px {
    font-size: 1.25rem;
}
.font-21px {
    font-size: 1.313rem;
}
.font-22px {
    font-size: 1.375rem;
}
.font-23px {
    font-size: 1.438rem;
}
.font-24px {
    font-size: 1.5rem;
}
.font-25px {
    font-size: 1.563rem;
}
.font-26px {
    font-size: 1.625rem;
}
.font-27px {
    font-size: 1.688rem;
}
.font-28px {
    font-size: 1.75rem;
}
.font-29px {
    font-size: 1.813rem;
}
.font-30px {
    font-size: 1.875rem;
}
.font-31px {
    font-size: 1.938rem;
}
.font-32px {
    font-size: 2rem;
}
.font-40px {
    font-size: 2.5rem;
}
.font-48px {
    font-size: 3rem;
}
.font-56px {
    font-size: 3.5rem;
}
.font-64px {
    font-size: 4rem;
}
