@import './../variables';

.App {
    font-family: sans-serif;
    text-align: center;
}

.horizontal-slider {
    /* width: 20rem; */
    width: 100%;
    max-width: 100%;
    height: 0.25rem;
    top: -0.45rem;

    &.single-direction {
        .example-thumb {
            &.example-thumb-0 {
                display: block !important;
                border: 0.25rem solid $turquoise;
                background-color: $white;
                transform: translateX(0);
                cursor: pointer;
            }
        }

        .example-track {
            // &.example-track-0{
            //   background-color: $turquoise;
            // }

            &.example-track-1 {
                background-color: $dark-grey;
            }
        }
        .mark {
            width: 2px;
            height: 12px;
            top: 16px;
            border-radius: 0.125rem;
            background-color: $dark-grey;
            padding: 0em !important;
        }
    }

    &.display-none {
        display: none;
    }

    .example-thumb {
        font-size: 0.9em;
        text-align: center;
        background-color: $primary-2;
        color: $white;
        cursor: pointer;
        border: 0.3125rem solid $gray;
        box-sizing: border-box;
        top: 0.813rem;
        width: 1rem;
        height: 1rem;
        border-radius: 50%;
        line-height: 1rem;
        transform: translateX(-0.5rem);

        // &.active {
        //   background-color: grey;
        // }

        &.example-thumb-1 {
            border: 4px solid $turquoise;
            background-color: $white;
        }
        &.example-thumb-2 {
            border: none !important;
            background-color: transparent !important;
            z-index: 1 !important;
        }

        &.example-thumb-0,
        &.example-thumb-3,
        &.example-thumb-4 {
            display: none;
        }

        &.example-thumb-0,
        &.example-thumb-2,
        &.example-thumb-3,
        &.example-thumb-4 {
            cursor: none;
        }
    }

    .example-track {
        position: relative;
        background: $dark-grey;
        top: 1.25rem;
        height: 0.25rem;
        border-radius: 0.125rem;

        &.example-track-2 {
            background: $turquoise !important;
            z-index: 1;
        }
        &.example-track-3 {
            background: $turquoise;
        }
    }
}
